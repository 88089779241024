import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import both from "../../images/boeken/love-duo.jpg";
import "./AlertModal.css"


export const AlertModal = ({show, setShow}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Woohoo je bericht is verzoden!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Row>

    <Col >
      <Image src={both}  />
<br/>
    We hebben je bericht ontvangen en gaan er zo spoedig mogelijk mee aan de slag!

Wanneer alles goed is gegaan krijg je ook een automatische bevestiging in uw mailbox.

Bedankt en tot snel!
Met liefdevolle groet,
Cat & Kamilla    </Col>
  </Row>
  

        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Terug
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}