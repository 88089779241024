import "./LoadingScreen.css"
import Lottie from 'react-lottie';
import waffleLottie from '../../lottie/waffle.json';
import { useEffect, useState } from "react";
const images = [
  "../../images/boeken/both.jpg",
  "../../images/boeken/cat-mil.jpg",
  "../../images/boeken/love-duo.jpg",
  "../../images/boeken/proef-cat.jpg",
  "../../images/boeken/verkoop-wafel.jpeg",
  "../../images/collage/dashboard.jpg",
  "../../images/collage/proef-cat.jpg",
  "../../images/collage/snijplank.jpg",
  "../../images/collage/truck-front.jpg",
  "../../images/collage/uitzoom-wafel-slagroom.jpg",
  "../../images/collage/wafel-naturel.jpg",
  "../../images/collage/wafel-slagroom.png",
  "../../images/collage/wafels-bord.jpg",
  "../../images/landing/landing-hq.jpg",
  "../../images/logo/logo.jpg",
]

const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = img.onabort = function () {
      reject(src)
    }
    img.src = src
  })
}

export const LoadingScreen = () => {
  return null;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: waffleLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false)

  useEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      const imagesPromiseList: Promise<any>[] = []
      for (const i of images) {
        imagesPromiseList.push(preloadImage(i))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setAssetsLoaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [])

  if (!assetsLoaded) {
    return <p>Preloading Assets</p>
  }

  return (
    <div className="load-container">
      <div className="relative">
        <div className="r-center">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
          KLEIN MOMENTJE (BEZIG MET LADEN)
        </div>
      </div>
    </div>
  )
}

