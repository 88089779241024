import "./ContactBlock.css";

export const ContactBlock = () => {
  return (
    <div className="center block cb-container" id="contact">
        <div className="text-wrap" data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="100">
            <div className="title">CONTACT</div>
            <br />
            <div className="email-cb">Bycatandmill@gmail.com</div>
            <br /> <br />
            <div>+31 6 15 30 40 04 / + 31 6 27 91 32 38</div>
            <br />
            <br />
        </div>
    </div>
)
}