import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from './images/logo/logo.jpg';
import './App.css';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { ImageBlock } from './components/ImageBlock/ImageBlock';
import { TextBlock } from './components/TextBlock/TextBlock';
import { ImageCollage } from './components/ImageCollage/ImageCollage';
import { TextBlockRows } from './components/TextBlockRows/TextBlockRows';
import { ContactBlock } from './components/ContactBlock/ContactBlock';
import { SubscribeForm } from './components/SubscribeForm/SubscribeForm';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ImageRow } from './components/ImageRow/ImageRow';
import { NavFooter } from './components/NavFooter/NavFooter';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';
import { Helmet } from 'react-helmet';
AOS.init();

function App() {
  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </Helmet>
      <div className="App" >
        <img alt="company logo" src={logo} className="image" data-aos="fade-in" data-aos-duration="2500" />
        <NavigationBar />
        <ImageBlock title="BY CAT&MILL" subtitle="FRESH HOME MADE WAFFLES & MORE" hasButton />
        <ImageRow />
        <SubscribeForm />
        <TextBlock />
        <ImageCollage />
        {/* <TextBlockRows /> */}
      
        <ContactBlock />

        <NavFooter />
      </div>
      <LoadingScreen />
    </>
  );
}

export default App;
