import { Col, Row } from 'react-bootstrap';
import './ImageCollage.css';

export const ImageCollage = () => {

    return (
            <Row className="cont" xs={1} md={1} lg={3}>
                <Col >
                    <Row className="img img-1" data-aos="zoom-in" data-aos-duration="2000">
                    </Row>
                    <Row>
                        <Col className="img img-2" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="200" ></Col>
                        <Col className="img img-3" data-aos="zoom-in-down" data-aos-duration="2500"></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="img img-4" data-aos="zoom-in-down" data-aos-duration="2500" data-aos-delay="100">
                    </Row>
                    <Row>
                        <Col className="img img-5" data-aos="zoom-in-right" data-aos-duration="2500"></Col>
                        <Col className="img img-6" data-aos="zoom-in-up" data-aos-duration="2500" data-aos-delay="100"></Col>
                    </Row>
                </Col>
                <Col >
                    <div data-aos="zoom-in-right" data-aos-duration="2500" className="img img-7 full-img" />
                </Col>
            </Row>
    )
}