import { Button } from 'react-bootstrap';
import './ImageBlock.css';
type ImageBlockProps = { title: string, subtitle: string, url?: string, hasButton?: boolean, style?: {} };

export const ImageBlock = ({ title, subtitle, url, style = {}, hasButton = false }:
    ImageBlockProps
) => {
    const background = url ? { backgroundImage: "url(" + url + ")" } : {};
    return (
        <div className="ib-container center background-image" style={{ ...style, ...background }} >
            <div className="text-wrap">
                <div className="ib-title main-title-ff" data-aos="zoom-in" data-aos-duration="1500">{title}</div>
                <div className="subtitle" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="500">{subtitle}</div>
                {hasButton && <> <br /><br /><Button data-aos-delay="500" data-aos="fade-up" data-aos-duration="1000"
                    onClick={() => window.scrollTo(0, (document.body as any).scrollHeight)}
                    variant="outline-dark" className="pink" size="lg">
                    Bestel hier wafels en meer!
                </Button></>}
            </div>
        </div>
    )
}