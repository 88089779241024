import { Container, Nav, Navbar } from "react-bootstrap"
import './NavigationBar.css';

export const NavigationBar = () => {

    return (
        <Navbar expand="lg" className="border" data-aos="fade-in" data-aos-duration="2500">
            <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{border: 'none'}} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="center me-auto">
                        <Nav.Link href="#home">HOME</Nav.Link>
                        <Nav.Link href="#about-us">OVER ONS</Nav.Link>
                        <Nav.Link href="#agenda">TRUCK AGENDA</Nav.Link>
                        <Nav.Link href="#contact">CONTACT</Nav.Link>
                        {/* <Nav.Link href="#menu">MENU</Nav.Link> */}
                        <Nav.Link href="#boeken">BOEKEN</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
                <br />
            </Container>
        </Navbar>
    )
}