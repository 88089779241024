import { Card, CardGroup, Container } from "react-bootstrap"
import both from '../../images/boeken/both.jpg';
import plank from '../../images/boeken/verkoop-wafel.jpeg';
import truck from '../../images/boeken/cat-mil.jpg';

import "./ImageRow.css"

export const ImageRow = () => {
  return (
    <Container className="ir-container">
    <div id="agenda" className="title title-ff" data-aos="fade-in" data-aos-duration="2500" data-aos-delay="100"> WAFELS BESTELLEN?</div>
<br />
    <div data-aos="fade-in" data-aos-duration="2500" data-aos-delay="100">Hieronder zijn al onze diensten weergegeven. Bent u eruit? Dan kunt u uw wens doorgeven via het contactformulier onderaan de pagina. Op basis van deze informatie krijgt u een factuur toegestuurd waarna u alles nog eens rustig en vrijblijvend kunt doorlezen. U kunt ook direct contact opnemen via: bycatandmill@gmail.com of bellen naar. T; Kamilla +31 6 15 30 4004 of Catharina +31 6 27 91 32 38</div>
    <br />  <br />
<CardGroup>
  <Card data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="100" style={{padding: 5, borderWidth: 0}}>
    <Card.Img variant="top" src={plank} />
    <Card.Body>
      <Card.Title>WAFELS OP LOCATIE
</Card.Title>
      <Card.Text>
Heeft u een leuk evenement waar wij de gasten blij mogen maken met heerlijke 
zelfgemaakte wafels? Neem dan vooral contact met ons op!
      </Card.Text>
    </Card.Body>
  </Card >
  <Card data-aos="zoom-in-down" data-aos-duration="2500" data-aos-delay="100" style={{padding: 5, borderWidth: 0}}>
    <Card.Img variant="top" src={truck} />
    <Card.Body>
      <Card.Title>EVENEMENT / FESTIVAL</Card.Title>
      <Card.Text>
       Organiseert u een festival en bent u opzoek naar de lekkerste
       wafels van Nederland? Dan gaan wij graag een samenwerking met u aan!
      </Card.Text>
    </Card.Body>
  </Card>
  <Card data-aos="zoom-in-right" data-aos-duration="2500" data-aos-delay="100" style={{padding: 5, borderWidth: 0}}>
    <Card.Img variant="top" src={both} />
    <Card.Body>
      <Card.Title>WAFELS OP BESTELLING</Card.Title>
      <Card.Text>
        Of wilt u soms lekker thuis genieten van onze verse zelfgemaakt wafels? Dan heeft u geluk want het is het nu ook mogelijk om
        te laten bezorgen!
      </Card.Text>
    </Card.Body>
  </Card>
</CardGroup>
<br />
</Container>
  )
}