import { Button, Col, Container, InputGroup, Row, Form } from "react-bootstrap"
import "./SubscribeForm.css"
import emailjs, { init } from 'emailjs-com';
import { useRef, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { AlertModal } from "../AlertModal/AlertModal";

init("user_gUEBwb5aHB68zm4xQWrwQ");
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object().shape({
  subject: yup.string().required(),
  name: yup.string().required(),
  phone: yup.string().matches(phoneRegExp, 'Oops, dit is geen geldig nummer').required(),
  message: yup.string().required(),
  email: yup.string().email('Dit is geen geldig email address..').required(),
});


export const SubscribeForm = () => {
  const [show, setShow] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const form = useRef();
  const sendEmail = (values: any) => {
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE as string, 
      process.env.REACT_APP_EMAILJS_TEMPLATE as string, 
      values, process.env.REACT_APP_EMAILJS_USER as string
      )
      .then((result) => {
          setShow(true)
      }, (error) => {
        // TODO: tell users that mail is not send
         // console.log(error.text);
      });
  };

  return (
    <div className="sf-bg" id="boeken"> 
    <Container className="sf-container">
    <div className="title" data-aos="zoom-in-up" data-aos-duration="2500">Wat kunnen wij voor u betekenen?</div>
    <br/>
    <br/>
       <Formik
      validationSchema={schema}
      onSubmit={sendEmail}
      initialValues={{
        subject: '',
        name: '',
        phone: '',
        email: '',
        message: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form data-aos="zoom-in-up" data-aos-duration="2500" data-aos-delay="200" noValidate onSubmit={handleSubmit}>
  <Form.Group  className="mb-3" controlId="formBasicEmail">
  <Form.Group as={Col} md="12" controlId="validationFormik00">
  <Form.Label>Selecteer een dienst</Form.Label>
  <Form.Select aria-label="Default select example" name="subject"
                value={values.subject}
                onChange={handleChange}
                isValid={touched.subject && !errors.subject}
                >
  <option>Klik hier</option>
  <option value="truck_op_locatie">Truck op locatie</option>
  <option value="evenementen_festivals">Evenement / festival</option>
  <option value="wafels_bestellen">Enkel wafels bestellen</option>
  <option value="overige">Overige reden</option>

</Form.Select>
</Form.Group>
<br />
            <Form.Group as={Col} md="12" controlId="validationFormik01">
              <Form.Label>Voor- en achternaam</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vul hier in.."
                name="name"
                value={values.name}
                onChange={handleChange}
                isValid={touched.name && !errors.name}
              />
              <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <br />
           
            <Form.Group as={Col} md="12" controlId="validationFormikUsername">
              <Form.Label>Email-adress</Form.Label>
              <InputGroup hasValidation>
             
                <Form.Control
                  type="text"
                  placeholder="Vul hier in.."
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <br />
            <Form.Group as={Col} md="12" controlId="validationFormik02">
              <Form.Label>Telefoonnummer (niet verplicht)</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Vul hier in.."
                value={values.phone}
                onChange={handleChange}
                isValid={touched.phone && !errors.phone}
              />

              <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group as={Col} md="12" controlId="validationFormik02">
              <Form.Label className="text-start">Hier kunt u uw boeking verder toelichten</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                placeholder="Vul hier in.."
                value={values.message}
                onChange={handleChange}
                isValid={touched.message && !errors.message}
              />

              <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
  </Form.Group>
  <br />
  <Button variant="dark"  type="submit" style={{width: '100%'}}>
    Versturen
  </Button>
</Form>
      )}
      </Formik>
      </Container>
      <AlertModal show={show} setShow={setShow} ></AlertModal>
</div>

  )
}