import './TextBlock.css';

export const TextBlock = () => {

    return (
        <div className="center block" id="about-us" >
            <div className="text-wrap"  >
                <div className="title" data-aos="fade-in" data-aos-duration="2000" >ABOUT US</div>
                <br />
                <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000" className="intro-text">Wij zijn, Catharina en Kamilla, twee zussen met een enorme passie voor koken & bakken, wat wij graag willen delen door het maken van heerlijke verse producten vanuit ons restaurant op wielen. 
 
 </div>
                <br /> <br />
                <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">Vanaf jongs af aan zijn wij beide al vaak in de keuken te vinden met het bakken van taartjes, gebakjes, koekjes, bonbons, noem maar op. Een restaurant is eigenlijk altijd al onze droom geweest en nu we ouder zijn durfden we eindelijk het risico te nemen door onze Opel Blitz in 2020 over te kopen. Na een jaar renoveren is de oorspronkelijke brandweerwagen omgebouwd tot een echte foodtruck. Vanuit onze foodtruck willen we wafels en andere zoete of hartige versnaperingen delen met iedereen waarbij we graag een glimlach toveren op jouw gezicht. Met onze producten willen we een geniet momentje creëren voor jouzelf of samen met je partner, familie of vrienden. Onze producten zijn altijd vers gemaakt, van pure ingrediënten uit onze woonomgeving De Noordoostpolder. </div>
                <br />
                <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000">Dankzij onze creatieve innovatieve mindset zullen wij blijven vernieuwen en ontwikkelen en dit zal terug te vinden zijn in onze baksels! </div>
            </div>
        </div>
    )
}